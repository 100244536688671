<template>
  <a-modal title="订单详情" v-model="visible" width="800px" :footer="null" @cancel="handleCancel">
    <div class="modal-order-box">
      <div class="box-title">项目信息</div>
      <div class="box-content">
        <div class="box-content-item">
          <div class="box-content-item-label">项目部</div>
          <div class="box-content-item-value">{{ projectDto.projectDepartment }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">项目名称</div>
          <div class="box-content-item-value">{{ projectDto.projectName }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">工种/专业</div>
          <div class="box-content-item-value">{{ demandDto.wbProject || demandDto.positionName }}</div>
        </div>
      </div>
      <div class="box-title">薪资信息</div>
      <div class="box-content">
        <div class="box-content-item">
          <div class="box-content-item-label">结算周期</div>
          <div class="box-content-item-value">{{ salaryDto.date }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">出勤（天）</div>
          <div class="box-content-item-value">{{ salaryDto.day }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">应发（元）</div>
          <div class="box-content-item-value">{{ salaryDto.payable }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">加班（小时）</div>
          <div class="box-content-item-value">{{ salaryDto.workOvertime }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">加班费（元）</div>
          <div class="box-content-item-value">{{ salaryDto.workOvertimePayment }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">扣款（元）</div>
          <div class="box-content-item-value">{{ salaryDto.deduction }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">实发</div>
          <div class="box-content-item-value">{{ salaryDto.realPay }}</div>
        </div>
      </div>
      <div class="box-title">工人信息</div>
      <div class="box-content">
        <div class="box-content-item">
          <div class="box-content-item-label">姓名</div>
          <div class="box-content-item-value">{{ userInfoDto.realName }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">注册账号ID</div>
          <div class="box-content-item-value">{{ userInfoDto.userId }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">身份证号</div>
          <div class="box-content-item-value">{{ userInfoDto.idCard }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">联系电话</div>
          <div class="box-content-item-value">{{ userInfoDto.phone }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">年龄</div>
          <div class="box-content-item-value">{{ userInfoDto.age }}</div>
        </div>
        <div class="box-content-item">
          <div class="box-content-item-label">性别</div>
          <div class="box-content-item-value">{{ userInfoDto.sex == 0 ? '男' : '女' }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalOrder',
  props: {},
  data () {
    return {
      visible: false,
      rowItem: {}
    }
  },
  computed: {
    projectDto () {
      return this.rowItem.projectDto || {}
    },
    salaryDto () {
      return this.rowItem.salaryDto || {}
    },
    userInfoDto () {
      return this.rowItem.userInfoDto || {}
    },
    demandDto () {
      return this.rowItem.demandDto || {}
    }
  },
  methods: {
    show (row) {
      this.visible = true
      this.rowItem = { ...row }
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.modal-order-box {
  color: #000000;
  .box-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .box-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0px;
    }
    &-item {
      display: flex;
      align-items: center;
      width: 50%;
      border-bottom: 1px solid #f5f7fa;
      &-label {
        color: #606266;
        text-align: right;
        background: #f5f7fa;
        box-shadow: inset 0px -1px 0px #ebeef5;
        width: 110px;
        padding-right: 15px;
        line-height: 36px;
        height: 36px;
      }
      &-value {
        flex: 1;
        height: 36px;
        line-height: 36px;
        padding-left: 15px;
      }
    }
  }
}
</style>
