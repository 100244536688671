<template>
  <div class="pay-stubs--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      企业工资条管理
    </div>
    <div class="content">
      <search-form ref="searchForm" @refresh="$refs.table.refresh()"></search-form>
      <p-table
        ref="table"
        row-key="orderNo"
        :scroll="{ y: 0 }"
        extraHeight="480"
        :sourceData="getDataApi"
        download
        fileName="企业工资条"
        :columns="columns"
        :extraDownColumn="extraDownColumn"
      >
        <template slot="action" slot-scope="text, record">
          <a-popconfirm
            title="确认删除当前数据嘛?"
            ok-text="是的"
            cancel-text="取消"
            v-if="record.salaryDto.status == 0"
            @confirm="handleDelete(record)"
          >
            <a-button type="link">删除订单</a-button>
          </a-popconfirm>
          <a-button
            type="link"
            v-if="record.salaryDto.status == 2 || record.salaryDto.thirdPartyStatus == 3"
            @click="handleModalSHow(record)"
          >查看备注</a-button
          >
          <a-button type="link" @click="$refs.modalOrder.show(record)">详情</a-button>
        </template>
      </p-table>
    </div>
    <modalOrder ref="modalOrder" />
  </div>
</template>

<script>
import searchForm from './search-form.vue'
import { queryTransfer, deleteById } from '@/api/pay-stubs'
import modalOrder from './modal-order.vue'
import { customModal } from '@/components/common/custom-dialog'

export default {
  name: 'PayStubs',
  components: {
    searchForm,
    modalOrder
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        // {
        //   title: '工资条订单号',
        //   dataIndex: 'id',
        //   key: 'id'
        // },
        {
          title: '工人姓名',
          dataIndex: 'userInfoDto.realName',
          download: true,
          key: 'realName',
          formatDownLoad: (t, r) => r.userInfoDto.realName
        },
        {
          title: '手机号',
          dataIndex: 'userInfoDto.phone',
          download: true,
          key: 'phone',
          formatDownLoad: (t, r) => r.userInfoDto.phone
        },
        {
          title: '薪资结算周期',
          dataIndex: 'salaryDto.date',
          download: true,
          key: 'date',
          formatDownLoad: (t, r) => r.salaryDto.date
        },
        {
          title: '金额',
          dataIndex: 'salaryDto.realPay',
          download: true,
          key: 'realPay',
          formatDownLoad: (t, r) => r.salaryDto.realPay
        },
        {
          title: '创建人',
          dataIndex: 'salaryDto.createBy',
          download: true,
          key: 'createBy',
          formatDownLoad: (t, r) => r.salaryDto.createBy
        },
        {
          title: '创建时间',
          dataIndex: 'salaryDto.createTime',
          key: 'createTime',
          download: true,
          ellipsis: true,
          formatDownLoad: (t, r) => r.salaryDto.createTime
        },
        {
          title: '状态',
          dataIndex: 'status',
          download: true,
          key: 'status',
          customRender: (t, r) => {
            const { status, thirdPartyStatus } = r.salaryDto
            if (thirdPartyStatus == 2) {
              return '发放成功'
            }
            if (thirdPartyStatus == 3) {
              return '发放失败'
            }
            if (status == 2) {
              return '审核不通过'
            }
            if (status == 0) {
              return '待审核'
            }
            return '发放中'
          },
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      extraDownColumn: [
        {
          title: '项目部',
          dataIndex: 'projectDto.projectDepartment',
          formatDownLoad: (t, r) => r.projectDto.projectDepartment
        },
        {
          title: '项目名称',
          dataIndex: 'projectDto.projectName',
          formatDownLoad: (t, r) => r.projectDto.projectName
        },
        {
          title: '工种/专业',
          dataIndex: 'demandDto.positionName',
          formatDownLoad: (t, r) => r.demandDto.positionName
        },
        {
          title: '身份证号',
          dataIndex: 'userInfoDto.idCard',
          formatDownLoad: (t, r) => r.userInfoDto.idCard
        }
      ],
      tableData: []
    }
  },
  computed: {
    enterpriseInfo () {
      return this.$store.getters.userInfo?.enterpriseInfoDto
    }
  },
  created () {},
  methods: {
    getDataApi (params) {
      const form = this.$refs.searchForm.getQuery()
      return queryTransfer({ ...params, ...form, enterpriseId: this.enterpriseInfo?.enterpriseId })
    },
    handleDelete ({ salaryDto }) {
      deleteById(salaryDto.id).then((res) => {
        if (res.success) {
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleModalSHow (row) {
      customModal({
        propsData: {
          title: '发薪备注',
          visible: true,
          width: 400,
          footer: null,
          keyboard: false,
          maskClosable: false
        },
        component: () => <div>{row.salaryDto?.remark}</div>
      })
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
.pay-stubs--wrapper {
  .table-btn {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
