<template>
  <a-form-model
    :model="query"
    class="search__form"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 18 }"
    labelAlign="left"
  >
    <a-row>
      <!-- <a-col :span="8">
        <a-form-model-item label="工资条订单号：">
          <a-input placeholder="工资条订单号" v-model="query.orderNo" allowClear></a-input>
        </a-form-model-item>
      </a-col> -->
      <a-col :span="8">
        <a-form-model-item label="工人姓名：">
          <a-input placeholder="工人姓名" v-model="query.realName" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="创建时间">
          <a-range-picker value-format="YYYY-MM-DD" v-model="query.date" />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="工人手机号：">
          <a-input placeholder="工人手机号" v-model="query.phone" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="发放状态：">
          <a-select
            :options="statusOptions"
            v-model="query.payStatys"
            placeholder="请选择发放状态"
            allowClear
          ></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <div class="form_btn_box">
          <a-button type="primary" @click="refresh">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'ProductHeader',
  props: {
    // statusOptions: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data () {
    return {
      query: {},
      statusOptions: [
        {
          value: 0,
          label: '审核中'
        },
        {
          value: 1,
          label: '审核不通过'
        },
        {
          value: 2,
          label: '发放成功'
        },
        {
          value: 3,
          label: '发放失败'
        }
      ]
    }
  },
  mounted () {
    this.roleType = this.handleGetDict('roleType')
  },
  methods: {
    reset () {
      this.$set(this, 'query', {})
    },
    refresh () {
      this.$emit('refresh')
    },
    getQuery () {
      const { date = [], payStatys, realName, phone } = this.query
      let status, thirdPartyStatus
      switch (payStatys) {
        case 0:
        status = 0
        thirdPartyStatus = 0
          break
          case 1:
          status = 2
          thirdPartyStatus = 0
          break
          case 2:
          status = 1
          thirdPartyStatus = 2
          break
          case 3:
          status = 1
          thirdPartyStatus = 3
          break
        default:
          break
      }
      return {
        realName,
        phone,
        status,
        thirdPartyStatus,
        createTime: date.join(',')
      }
    }
  }
}
</script>

<style lang="less">
.search__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
