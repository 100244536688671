import request from '@/utils/request'

// 台账
export function queryTransfer (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/queryTransfer',
    method: 'get',
    params: parameter
  })
}

// 台账
export function deleteById (id) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSalaryManagementController/del/${id}`,
    method: 'delete'
  })
}
